@tailwind base;
@tailwind components;
@tailwind utilities;

@media only screen and (min-width: 1024px) {
  .content{
    height: 105px
  }
}

@media only screen and (max-width: 1023px) {
  .content{
    height: 52px;
    transition: height ease 0.5s;
  }
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-BoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-ExtraBold.ttf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-ExtraBoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Black.ttf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-BlackItalic.ttf');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Black.ttf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}